@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-itqan-bg;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: #007b55;
  border-radius: 12px;
  transition: all 0.3s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: #08480b;
}

.navbar-item .active {
  @apply bg-itqan-green-200 text-white;
}
.navbar-item .active svg {
  @apply text-white;
}

.pagination-active {
  @apply !bg-white !text-itqan-green-50;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  @apply !bg-itqan-green-50;
}

input[type="radio"]:checked {
  background-color: #007b55;
}
